import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax, RefField,
} from 'ants/resource';
import { arrayAsObject } from 'lib/utils';
import moment from 'moment';
import {
  PlayerCreateMutation,
  PlayerReadQuery,
  PlayerSearchQuery,
  PlayerUpdateMutation,
  PlayerBasicDataUpdateMutation,
  PlayerNameQuery,
  PlayerImageUploadMutation,
  PlayerHeadlessSearchQuery,
  MultiPlayerCreateMutation,
  PlayerReviewMutation,
} from './query';
import { PlayerImageView } from './ResourceImage';

const playerDateOFBirthValidator = () => {
  const validateDate = (value, context) => {
    if (!value) {
      return true;
    }
    const date = moment(value, 'YYYY-MM-DD');
    if (!date.isValid() || date.year() < 1800 || date.year() > moment().year()) {
      return context.createError({ message: 'Invalid date of birth' });
    }

    return true;
  };

  return Yup.string().nullable().test(
    'player-date-validate',
    validateDate,
  );
};

export const PlayerResource = Resource({
  resourceId: 'player',
  name: 'Player',
  storeId: 'Player',
  pageKey: 'sports.cricket.players',
  columnSets: [
    ColumnSet({
      name: 'Player',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        jersey_name: Yup.string().nullable().default(),
        gender: Yup.string().required(),
        jersey_number: Yup.number().nullable().default(),
        birth_place: RefField(),
      }),
      referenceFields: ['birth_place'],
    }),
    ColumnSet({
      name: 'Bio',
      shape: Yup.object().shape({
        legal_name: Yup.string().nullable().default(),
        date_of_birth: playerDateOFBirthValidator(),
        height: Yup.number().nullable().default(),
        weight: Yup.number().nullable().default(),
        summary: Yup.string().nullable().default(''),
        official_website: Yup.string().nullable().default(),
        retired: Yup.boolean().default(false),
      }),
    }),
    ColumnSet({
      name: 'SocialNetwork',
      shape: Yup.object().shape({
        facebook: Yup.string().nullable().default(),
        twitter: Yup.string().nullable().default(),
        instagram: Yup.string().nullable().default(),
        youtube: Yup.string().nullable().default(),
      }),
      viewKey: 'social_network',
    }),
    ColumnSet({
      name: 'Cricket',
      shape: Yup.object().shape({
        playing_role: Yup.string().required('required'),
        batting_style: Yup.string().nullable().default(),
        bowling_style: Yup.mixed().nullable().default(),
        alltime_roles: Yup.mixed().required('required'),
      }),
      historyKey: 'CricketPlayerCricket',
    }),
    ColumnSet({
      name: 'Data',
      shape: Yup.object().shape({
        keys: Yup.mixed().nullable().default(),
        tags: Yup.mixed().nullable().default(),
        properties: Yup.mixed().nullable().default(),
      }),
      viewKey: 'data',
      historyKey: 'CricketPlayerData',
    }),
    ColumnSet({
      name: 'LegacyData',
      shape: Yup.object().shape({
        key: Yup.string().nullable().default(),
      }),
      viewKey: 'legacy_data',
      historyKey: 'PlayerLegacyData',
    }),
    ColumnSet({
      name: 'Computed',
      shape: Yup.object().shape({
        associations: Yup.mixed().nullable().default(),
        teams: Yup.mixed().nullable().default(),
        recent_matches: Yup.mixed().nullable().default(),
        tournament_teams: Yup.mixed().nullable().default(),
      }),
      viewKey: 'computed',
      historyKey: 'CricketPlayerComputed',
    }),
    ColumnSet({
      name: 'Image',
      shape: Yup.object().shape({
        image_url: Yup.string().nullable().default(''),
      }),
    }),
    ColumnSet({
      name: 'review',
      shape: Yup.object().shape({
        private_notes: Yup.string().nullable().default(''),
        review_status: Yup.boolean().nullable().default(false),
        hide_from_search: Yup.boolean().nullable().default(false),
        replaced_with: Yup.mixed().required('Duplicate player required'),
      }),
      historyKey: 'PlayerReview',
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
    { action: 'updateBasicData' },
    { action: 'updateImage' },
    { action: 'updateDuplicate', name: 'Edit duplicate' },
  ],
  imageRender: (item) => (<PlayerImageView item={item} size="24" />),
  queries: {
    read: QueryAction({
      query: PlayerReadQuery,
      resourceNamePath: 'item.player.name',
      resourcePath: 'item.resource.hashkey',
      responsePath: 'sports_player_read',
    }),
    list: QueryAction({ query: PlayerSearchQuery, responsePath: 'sports_player_search' }),
    search: QueryAction({
      query: PlayerSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'player.name',
      responsePath: 'sports_player_search',
    }),
    headlessSearch: QueryAction({
      query: PlayerHeadlessSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'player.name',
      responsePath: 'sports_player_review',
    }),
    name: QueryAction({ query: PlayerNameQuery, responsePath: 'sports_player_name', resourceNamePath: 'sports_player_name.name' }),
  },
  mutations: {
    update: MutationAction({
      mutation: PlayerUpdateMutation,
      cs: ['player', 'bio', 'cricket', 'social_network', 'data', 'legacy_data', 'computed'],
      responsePath: 'sports_player_update',
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        resp.cricket.bowling_style = arrayAsObject(resp.cricket.bowling_style) ?? {};
        resp.cricket.alltime_roles = arrayAsObject(resp.cricket.alltime_roles) ?? [];
        return resp;
      },
    }),
    updateBasicData: MutationAction({
      mutation: PlayerBasicDataUpdateMutation,
      cs: ['cricket', 'player'],
      responsePath: 'sports_player_basic_data_update',
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        resp.cricket.bowling_style = arrayAsObject(resp.cricket.bowling_style) ?? {};
        resp.cricket.alltime_roles = arrayAsObject(resp.cricket.alltime_roles) ?? [];
        return resp;
      },
    }),
    updateDuplicate: MutationAction({
      mutation: PlayerReviewMutation,
      cs: ['review'],
      responsePath: 'sports_player_review_update',
    }),
    updateImage: MutationAction({ mutation: PlayerImageUploadMutation, cs: ['image'] }),
    create: MutationAction({ mutation: PlayerCreateMutation, responsePath: 'sports_player_create' }),
    multiCreate: MutationAction({ mutation: MultiPlayerCreateMutation, responsePath: 'sports_player_multiple_create' }),
  },
});
