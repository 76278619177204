import React, { useContext, useState } from 'react';
import { ReactComponent as WebtreeIcon } from 'assets/cms/icon-webtree.svg';
import { ReactComponent as StructureIcon } from 'assets/cms/icon-structure.svg';
import { ReactComponent as ContentIcon } from 'assets/cms/icon-content.svg';
import { ReactComponent as WebsiteIcon } from 'assets/cms/icon-website.svg';
import { ReactComponent as AssetIcon } from 'assets/cms/icon-asset.svg';
import { ReactComponent as LinkListIcon } from 'assets/cms/icon-linklist.svg';
import { ReactComponent as PageIcon } from 'assets/cms/icon-page.svg';
import { TreeView } from 'cms/webtree/TreeView';
import { Tooltip } from 'core/Tooltip';
import { Pages } from 'core/pages';
import { Tabs } from 'core/Tabs';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { useLazyQuery } from '@apollo/client';
// import { ListController } from 'core/ListController';
import { Link, useNavigate } from 'react-router-dom';
import { Text14Regular } from 'style/typography';
import { ResourceListController } from 'ants/ResourceList';
import { TopicActiveUsers } from 'ants/ActiveUsers';
import { PropTypes } from 'prop-types';
import { UserContext } from 'core/UserContext';
import { getActiveTab, saveActiveTab } from 'core/localStore';
import { motion } from 'framer-motion';
import {
  ReaderIcon,
} from '@radix-ui/react-icons';
import { WebsiteResource } from './website/resource';
import { CmsStructureResource } from './structure/resource';
import { CmsAssetResource } from './asset/resource';
import { CmsLinkListResource } from './linklist/resource';
// import { CmsStructureContentResource } from './structureContent/resource';
import { WebTreeResourceQuery } from './webtree/query';
import { CmsPageResource } from './page/resource';
import { AssetPreviewKey } from './asset/AssetPreview';

export const MenuManagementWrapper = styled.div`
  .collapsible {
    .options-wrapper {
      max-height: 55vh;
      overflow-y: auto;
    }
  }
  .options-wrapper {
    display: block;
    >div {
      &[data-orientation="vertical"] {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        button {
          min-width: ${asRem(20)};
          min-height: ${asRem(20)};
          max-width: ${asRem(20)};
          padding: 0;
          box-shadow: none;

          span {
            padding: 0;
          }
        }
      }
      >div[role="tablist"] {
        padding: 0;
        gap: ${asRem(14)};
        margin: 0 0 ${asRem(20)} ;

        button {
          display: flex;
          padding: 0 0 ${asRem(12)};

          &[data-state="active"] {
            span {
              svg {
                color: var(--color-button-primary-bg);
              }
            }
          }

          span {
            display: flex;
            svg {
              width: ${asRem(16)};
              height: ${asRem(16)};
            }
          }
        }
      }

      >div[role="tabpanel"] {
        margin: 0;
        .wrapper {
          >div {
            >div {
              margin: 0;
              padding: 0;

              >div {
                margin: 0;
                padding: 0;
              
                .links-wrapper {
                  overflow-y: auto;
                  max-height: 45vh;
                  overflow-x: hidden;
                  color: var(--color-text-2);
                }
              }
            }
          }
          
        }
       
      }
    }
  }

  .listview-content {
    .listview-items {
      .page-tools {
        margin: ${asRem(10)};
      }
    }
  }

  .listview-title-bar {
    display: flex;
    justify-content: start;
    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: ${asRem(8)};
      color: var(--color-text-2);

      svg {
        width: ${asRem(16)};
        height: ${asRem(16)};
      }
    }
  }
  

  .list-view-wrapper { 
    margin: 0;
  }
  .links-wrapper {
    a {
      display: flex;
      gap: ${asRem(8)};
      flex: 0 0 70%;
      max-width: ${asRem(180)};
      word-break: break-all;
    }
    transiton: all 0.3s linear;

    >div {
      >div:not(.actions) {
        display: flex;
      }
      .actions {
        display: none;
        color: var(--color-highlight-1);
      }
    }

    &:hover {
      >div {
        >div:not(.actions) {
          display: none;
        }
        .actions {
          display: flex;
        }
      }
    }

   

    >div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: ${asRem(8)};
      padding: ${asRem(8)};
      flex: 0 0 100%;

      &:hover, &.active {
        background: var(--color-button-primary-bg-overlay);
        border-radius: ${asRem(4)};
      }

    }
  }

  .display-20-bold {
    display: none;
  }
  .tree-editor {
    display: flex;
    justify-content: space-between;
    padding: ${asRem(40)} 0;
    gap: ${asRem(20)};
    .list-title-block {
      --color-button-primary-bg: var(--color-t2-button-primary-bg);
      --color-secondary-cta: var(--color-t2-secondary-cta);
    }
    .tree-container {
      width: ${asRem(280)};
      .list-title-block {
        padding: 0 ${asRem(16)};
        .display-18-regular {
          padding-bottom: ${asRem(12)};
        }
      }
    }
    .tree-preview {
      flex: 1;
    }
  }
`;

export function MenuManagement({ viewMode }) {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { user } = userContext;

  const [selectedNode, setSelectedNode] = useState(null);
  const [activeResource, setActiveResource] = useState(null);

  let cmsMenus = [
    {
      icon: <WebtreeIcon />,
      title: 'Webtree',
      link: Pages.cms.webtree.index.meta.url,
      key: 'webtree',
      resource: WebsiteResource,
    },
    {
      icon: <WebsiteIcon />,
      title: 'Website',
      link: Pages.cms.website.index.meta.url,
      key: 'website',
      resource: WebsiteResource,
    },
    {
      icon: <PageIcon />,
      title: 'Page',
      link: Pages.cms.page.index.meta.url,
      key: 'page',
      resource: CmsPageResource,
    },
    {
      icon: <AssetIcon />,
      title: 'Asset',
      link: Pages.cms.asset.index.meta.url,
      key: 'asset',
      resource: CmsAssetResource,
      responseKey: 'cms_asset_search',
    },
    {
      icon: <LinkListIcon />,
      title: 'LinkList',
      link: Pages.cms.linklist.index.meta.url,
      key: 'linkList',
      resource: CmsLinkListResource,
      responseKey: 'cms_linklist_search',
    },
    {
      icon: <StructureIcon />,
      title: 'Structure',
      link: Pages.cms.structure.index.meta.url,
      key: 'structure',
      resource: CmsStructureResource,
      responseKey: 'cms_structure_search',
    },
    {
      icon: <ContentIcon />,
      title: 'Structure Content',
      key: 'structureContent',
      resource: CmsStructureResource,
      responseKey: 'cms_structure_content_menu',
    },
  ];

  let getAccount = null;
  if (user?.accounts?.accounts.length > 0) {
    getAccount = user?.accounts?.accounts?.filter(
      (account) => {
        return userContext.accountKey === account.account.key;
      },
    );
    const isAdmin = user.roles.roles_v2.includes(1) || user.roles.roles_v2.includes(50);
    if (!isAdmin && (getAccount[0].role_v2 === 500)) {
      const ignoreMenus = ['page', 'website', 'structure'];
      cmsMenus = cmsMenus.filter((menu) => !ignoreMenus.includes(menu.key));
    }
  }

  const [getKeys, { data }] = useLazyQuery(WebTreeResourceQuery);

  const onNodeCreateShow = (node) => {
    if (node) {
      userContext.setSelectedTree(node);
      const key = userContext.selectedWebsite;
      navigate(`${Pages.cms.webtree.create.meta.urlFor({ key })}`);
    }
  };

  const onNodeSelect = (node) => {
    if (!node) return;
    if (node) {
      userContext.setSelectedTree(node);
      getKeys({
        variables: {
          resource: node.resource,
        },
      }).then((res) => {
        if (data && res) {
          setSelectedNode(data?.cms_webtree_read?.item?.name?.path);
          navigate(`${Pages.cms.page.update.meta.urlFor(
            { key: data?.cms_webtree_read?.item?.props?.page?.hashkey },
          )}`);
        }
      }).catch((error) => {
        console.error('Error', error);
      });
    }
  };

  const navigateToLink = (item) => {
    const menu = cmsMenus.find((val) => val.key === item);
    if (menu?.link) {
      navigate(menu.link);
      window.location.reload();
    }
  };

  const redirectLink = (val, item) => {
    if (val?.item?.key === 'structureContent') {
      return Pages.cms.structure_content.sc.list.meta.urlFor({
        sc: item?.resource?.key,
      });
    }

    if (val?.item?.key === 'linklist' || val?.item?.key === 'structure') {
      return val?.item?.resource?.pageFor(val?.item?.resource, 'update')?.meta.urlFor({ key: item?.resource?.hashkey });
    }

    return val?.item?.resource?.pageFor(val?.item?.resource, 'read')?.meta.urlFor({ key: item?.resource?.hashkey });
  };

  const redirectPreviewLink = (val, item) => {
    if (val?.item?.key === 'structureContent') {
      return Pages.cms.structure_content.sc.list.meta.urlFor({
        sc: item?.resource?.key,
      });
    }

    if (val?.item?.key === 'linklist' || val?.item?.key === 'structure') {
      return navigate(val?.item?.resource?.pageFor(val?.item?.resource, 'read')?.meta.urlFor({ key: item?.resource?.hashkey }));
    }

    return navigate(val?.item?.resource?.pageFor(val?.item?.resource, 'read')?.meta.urlFor({ key: item?.resource?.hashkey }));
  };

  const actionToggleAnimations = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '80%' },
  };

  return (
    <MenuManagementWrapper className={viewMode}>
      <div className="options-wrapper">
        <Tabs
          defaultKey={(getActiveTab() !== 'null' || getActiveTab() === null) ? getActiveTab() : cmsMenus?.[0]?.key}
          items={cmsMenus}
          onValueChange={(e) => {
            userContext.setSelectedTree(e);
            saveActiveTab(e);
            navigateToLink(e);
          }}
          orientation={viewMode === 'collapsible' ? 'vertical' : 'horizontal'}
          renderTabTitle={(item) => (
            <Tooltip
              text={item?.item?.title}
              renderTrigger={() => (
                <span>
                  {item?.item?.icon}
                </span>
              )}
            />
          )}
          renderContent={(val) => (
            <div className="wrapper">
              {viewMode !== 'collapsible' && (
                <div>
                  {val?.item?.title === 'Webtree'
                    ? (
                      <div className="tree-container">
                        <div className="tree-box">
                          <TreeView
                            showNewNodeLink={false}
                            onNodeSelect={onNodeSelect}
                            onNewNode={onNodeCreateShow}
                            selectedNode={selectedNode}
                          />
                        </div>
                      </div>
                    )
                    : (
                      <ResourceListController
                        resourceDef={val?.item?.resource}
                        action={val?.item?.key === 'structureContent'
                          ? val?.item?.resource?.queries.menu
                          : val?.item?.resource?.queries.search}
                        variables={{
                          search: '',
                        }}
                        ignoreLayoutAction
                        renderTitle={() => (
                          <div className="title">
                            {val?.item?.icon}
                            <Text14Regular>
                              {val?.item?.title}
                            </Text14Regular>
                          </div>
                        )}
                        render
                        useSearch
                        showActions={false}
                        renderRows={(items) => (
                          <div className="links-wrapper">
                            {items.map((item) => (
                              <motion.div className={item?.resource?.hashkey === activeResource ? 'active' : ''} whileHover="open" initial="closed" animate="closed">
                                <Link
                                  className="plain clear"
                                  to={redirectLink(val, item)}
                                  onClick={() => setActiveResource(item?.resource?.hashkey)}
                                >
                                  {val?.item?.key === 'asset' && (
                                  <AssetPreviewKey hashkey={item?.resource?.hashkey} size="xs" />
                                  )}
                                  <Text14Regular>
                                    {item?.name?.name
                                      || item?.name?.internal_name || item?.name?.filename
                                      || item?.page?.name}
                                    {val?.item?.key === 'structureContent' ? ' Items' : ''}
                                  </Text14Regular>
                                </Link>
                                <TopicActiveUsers
                                  topicPath={`${val?.item?.resource?.resourceId}/editor/${item?.resource?.hashkey}`}
                                  size="20"
                                  maxUsers={2}
                                />
                                <motion.div
                                  className="actions"
                                  variants={actionToggleAnimations}
                                >
                                  <Tooltip
                                    text="Preview"
                                    renderTrigger={() => (
                                      <button
                                        className="clear plain compact"
                                        type="button"
                                        onClick={() => redirectPreviewLink(val, item)}
                                        aria-label="Preview"
                                      >
                                        <ReaderIcon />
                                      </button>
                                    )}
                                  />
                                </motion.div>

                              </motion.div>
                            ))}
                          </div>
                        )}
                      />
                    )}
                </div>
              )}
            </div>
          )}
        />
      </div>
    </MenuManagementWrapper>
  );
}

MenuManagement.propTypes = {
  viewMode: PropTypes.string,
};
