import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const WebTreeCreateHintQuery = gql`
query WebTreeCreateHintQuery($name: String!) {
  cms_webtree_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        webtree
        hashkey: _hashkey
      }
      name {
        name
      }
    }
    key_suggestions
  }
}
`;

export const WebTreeCreateMutation = gql`
mutation WebTreeCreate(
  $webtree: WebTreeWebTreeInput!,
  $key: String,
) {
  cms_webtree_create(
    webtree: $webtree,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const WebPropsHeadFields = `
  link {
    id
    rel
    href
    l_as
    crossorigin
    media
    onload
    attrs { key value }
    index
  }
  tag {
    id
    tag
    content
    attrs { key value }
    index
  }
  script {
    id
    tag
    content
    attrs { key value }
    index
    src
    defrs
  }
`;

export const WebPropsFields = `
  title
  desc
  breadcrumb_name
  title_suffix
  desc_suffix
  access {
    req_user
    req_account
    req_role
    for_users
    for_accounts
    req_account_role
  }
  head_items {
    ${WebPropsHeadFields}
  }
  body_begin_items {
    ${WebPropsHeadFields}
  }
  body_end_items {
    ${WebPropsHeadFields}
  }
  og {
    title
    desc
    title_prefix
    desc_prefix
    img {
      account
      key
      hashkey: _hashkey
    }
    url
    attrs { key value }
  }
  headers {
    status
    new_loc
    new_tree_key
    headers { key value }
  }
`;

export const WebPropsInheritenceFields = `
    nothing
    all_headers
    all_og
    title_suffix
    desc_suffix
    access
`;

export const WebTreeReadQuery = gql`
query WebTreeReadQuery($resource: WebTreeInput!, $withHistory: Boolean) {
  cms_webtree_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        website
        key
        hashkey: _hashkey
      }
      name {
        path
      }
      webtree {
        path
        page { hashkey: _hashkey }
        level
        index
        parent { hashkey: _hashkey }
        note
      }
      stats {
        children_count
      }
      props {
        page { hashkey: _hashkey }
        props {
          ${WebPropsFields}
        }
        website_inheritence {
          ${WebPropsInheritenceFields}
        }
        level0_inheritence {
          ${WebPropsInheritenceFields}
        }
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const WebTreeUpdateMutation = gql`
mutation WebTreeUpdateMutation(
  $resource: WebTreeInput!,
  $props: WebTreePropsInput!,
  $tracking_name: String,
  $version: RecordStage) {
  cms_webtree_update(
    resource: $resource,
    props: $props,
    tracking_name: $tracking_name,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const WebTreeUpdatePathMutation = gql`
mutation WebTreeUpdatePathMutation(
  $resource: WebTreeInput!,
  $newPath: String!,
  $newParent: WebTreeInput,
  $changeParent: ChangeParent = NoChange,
  $note: String,
) {
  cms_webtree_rename(
    resource: $resource,
    new_path: $newPath,
    new_parent: $newParent,
    parent_change: $changeParent,
    note: $note,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const WebTreeDeleteMutation = gql`
mutation  WebTreeDeleteMutation(
  $resource: WebTreeInput!,
  $name: String!) {
  cms_webtree_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const WebTreeSearchQuery = gql`
query WebTreeSearchQuery($search: String!, $page: PagedRequestInput) {
  cms_webtree_search(search: $search, page: $page) {
    items {
      resource {
        account
        website
        key
        hashkey: _hashkey
      }
      name {
        path
      }
      webtree {
        path
        page { hashkey: _hashkey }
        level
        index
        parent { hashkey: _hashkey }
        note
      }
      stats {
        children_count
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const WebTreeNameQuery = gql`
query WebTreeNameQuery($resource: WebTreeInput!) {
  cms_webtree_name(resource: $resource) {
    name
  }
}
`;

export const WebTreeNodesQuery = gql`
query WebTreeNodesQuery($website: WebsiteInput!) {
  cms_webtree_nodes(website: $website) {
    raw
  }
}
`;

export const WebTreeResourceQuery = gql`
query WebTreeResourceQuery($resource: WebTreeInput!, $withHistory: Boolean) {
  cms_webtree_read(resource: $resource, with_history: $withHistory) {
    item {
      props {
        page { hashkey: _hashkey }
      }
    }
  }
}
`;
